<template>
  <div>
    <span>{{$t('t.Periods')}}</span>
    <v-radio-group
      class="ml-2"
      v-model="computedType"
      :column="false"
    >
      <v-radio
        :label="$t(`t.DatesSeries`)"
        value="dates-series"
        class="mr-4"
      ></v-radio>
      <v-radio
        :label="$t(`t.Custom`)"
        value="custom"
      ></v-radio>
    </v-radio-group>
    <div v-if="computedType === 'custom'">
      <div
        v-for="(period, idx) in computedPeriods"
        :key="idx + '_period'"
        class="d-flex flex-row mr-2 align-center"
      >
        {{period}}
        <date-filters
          :value="dataPeriods[idx].date"
          @input="setDate(idx, $event)"
          low
          :label="$t('t.Date')"
          class="value"
          ref="input"
          :rules="dateRules"
        />
        <translations
          class="ml-4"
          :label="$t('t.Label')"
          :is-new="true"
          :rules="nameRules"
          :translations.sync="dataPeriods[idx].label"
          @update:translations="setLabel(idx, $event)"
        />
        <v-btn
          class="ml-2"
          v-if="dataPeriods.length != 1 && !readonly"
          icon
          small
          @click="removeValue(idx)"
        >
          <v-icon small>{{$icon('i.Delete')}}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          v-if="idx === dataPeriods.length -1 && !readonly"
          icon
          small
          color="secondary"
          @click="addValue"
        >
          <v-icon small>{{$icon('i.Plus')}}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  components: {
    DateFilters: () => import('../../date-filters'),
    Translations: () => import('@/components/translations')
  },
  computed: {
    computedType: {
      get () {
        return this.dataType
      },
      set (v) {
        this.dataType = v
        this.dataDocumentEmit()
      }
    },
    computedPeriods: {
      get () {
        return this.dataPeriods
      },
      set (v) {
        this.dataPeriods = v
        this.dataDocumentEmit()
      }
    },
    dateRules () {
      return [v => !!v?.length || this.$t('t.IsRequired')]
    },
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    }
  },
  methods: {
    addValue () {
      this.dataPeriods.push({ date: null, label: null })
      this.dataDocumentEmit()
    },
    removeValue (idx) {
      this.dataPeriods.splice(idx, 1)

      if (this.dataPeriods.length === 0) {
        this.dataPeriods = [{ date: null, label: null }]
      }
      this.dataDocumentEmit()
    },
    setDate (idx, value) {
      this.dataPeriods[idx].date = this.lodash.cloneDeep(value)
      this.dataDocumentEmit()
    },
    setLabel (idx, value) {
      this.dataPeriods[idx].label = this.lodash.cloneDeep(value)
      this.dataDocumentEmit()
    },
    emitDocument () {
      const doc = { type: this.dataType }
      if (this.dataType === 'custom') {
        doc.periods = this.dataPeriods
      }
      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataPeriods: [null],
      dataType: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    document: Object,
    readonly: Boolean,
    disabled: Boolean
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataType = v?.type || 'dates-series'
        this.dataPeriods = v?.periods?.length ? this.lodash.cloneDeep(v.periods) : [{ date: null, label: null }]
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
